.container {
    width: 90%;
    margin: auto;
    /* padding: 50px 0; */
    /* background-color: red; */
}

.heading {
    /* background-color: pink; */
    padding: 0px 0 50px 0;
    display: flex;
    align-items: center;
}

.heading>.left {
    font-size: 40px;
    font-weight: 700;
    color: var(--soulsync-blue);
    width: max-content;
}

.heading>.right {
    border: 1px solid var(--soulsync-orange);
    width: 300px;
    margin-left: 20px;
}

/* SERVICE CIRCLES */
.main_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* background-color: aqua; */
}

.middle_container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    font-weight: bold;
    color: var(--soulsync-grey);
    /* background-color: rgb(241, 113, 113); */
}

.circle1 {
    /* background-color: lime; */
    width: 300px;
    border: 2px solid var(--soulsync-orange);
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 100%;
    position: relative;
    z-index: 99 !important;
    background-color: white;
}

.circle2 {
    z-index: 1 !important;
    position: absolute;
    background-color: var(--soulsync-orange);
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 100%;
    color: white;
}

.circle1 p,
.circle2 p {
    letter-spacing: 0;
    text-align: center;
    padding-top: 20px;
}


.container1_phone {
    display: none;
}

.sub_container {
    margin: auto;
    padding: 70px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: palegoldenrod; */
}

.left {
    width: 35%;
    font-weight: 700;
    text-align: left;
    font-size: 52px;
    color: var(--soulsync-orange);
}

.left img {
    padding: 50px 0;
    width: 65%;
}


.right {
    width: 50%;
    /* border: 1px solid lime; */
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
}

.phone,
.background {
    position: absolute;
    margin: auto;
    text-align: center;
}

.right .phone img {
    width: 40% !important;
    z-index: 9;
    margin: auto;
}

.right .background img {
    width: 50%;
    z-index: 1;
    margin: auto;
}

.sub_container .right_mobile {
    position: relative;
    display: none;
}

.sub_container {
    margin: auto;
    /* background-color: palegoldenrod; */
    padding: 70px 0 200px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left {
    width: 35%;
    font-weight: 700;
    text-align: left;
    font-size: 52px;
    color: var(--soulsync-orange);
}

.left img {
    padding: 50px 0;
    width: 65%;
}

.right {
    width: 50%;
    /* border: 1px solid lime; */
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
}

.phone,
.background {
    position: absolute;
    margin: auto;
    text-align: center;
}

.right .phone img {
    width: 40% !important;
    z-index: 9;
    margin: auto;
}

.right .background img {
    width: 50%;
    z-index: 1;
    margin: auto;
}

.flex_button {
    display: flex;
    justify-content: space-around;
}

.flex_button img {
    width: 100%;
}

@media (max-width:1440px) {
    .circle1 {
        width: 250px;
        height: 250px;
    }

    .circle2 {
        width: 250px;
        height: 250px;
    }

    .sub_container .left {
        /* height: 300px; */
        width: 600px;
    }

    .sub_container .right_mobile {
        position: relative;
        display: none;
    }
}

@media (max-width:576px) {


    /* SERVICE CIRCLES */
    .container1,
    .container1_left,
    .main_container,
    .container1_right {
        display: none !important;
    }

    .container1_phone {
        width: 100%;
        margin: auto;
        position: relative !important;
        display: flex !important;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .container1_left_phone,
    .container1_right_phone {
        position: relative !important;
        display: flex !important;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
    }

    .circle1_phone,
    .circle2_phone,
    .circle3_phone,
    .circle4_phone {
        position: relative;
        border: 3px solid var(--soulsync-orange);
        background-color: rgb(255, 255, 255);
        color: var(--soulsync-grey);
        text-align: center;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        z-index: 2;
        font-size: 12px;
        font-weight: 700;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    .circle2_phone {
        position: absolute !important;
        z-index: 1;
        /* left: 159px; */
        color: white;
        background-color: var(--soulsync-orange);
    }

    .circle2_phone {
        position: relative !important;
        text-align: center;
        width: 200px;
        height: 200px;
        border-radius: 50%;
        z-index: 2;
        font-size: 12px;
        font-weight: 700;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    .circle4_phone {
        position: absolute;
        z-index: 1;
        left: 159px;
        color: white;
        background-color: var(--soulsync-orange);
    }

    .circle3_phone,
    .circle4_phone {
        margin-top: 50px;
    }

    .circle1_phone p,
    .circle2_phone p,
    .circle3_phone p,
    .circle4_phone p {
        padding: 10px 20px;
        /* background-color: red; */
    }

    .circle1_phone img,
    .circle2_phone img,
    .circle3_phone img,
    .circle4_phone img {
        width: 20%;
    }

    .container {
        width: 90%;
        margin: auto;
        /* padding: 50px 0; */
        /* background-color: red; */
    }

    .heading {
        /* background-color: pink; */
        padding: 50px 0;
        display: flex;
        align-items: center;
    }

    .heading>.left {
        font-size: 24px;
        font-weight: 700 !important;
        letter-spacing: 0;
        color: var(--soulsync-blue);
        width: max-content;
        white-space: nowrap;
    }

    .heading>.right {
        border: 1px solid var(--soulsync-orange);
        width: 100%;
        /* margin-left: 2000px; */
    }


    .service_container {
        display: flex;
        align-items: center;
        padding: 50px 0;
        /* justify-content: center; */
        /* background-color: rgb(226, 244, 255); */
    }

    .service_circle {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        border: 5px solid var(--soulsync-orange);
        border-radius: 100%;
        padding: 100px;
        width: 500px;
        z-index: 2;
        position: relative;
        color: var(--soulsync-grey);
        ;
    }

    .service_circle2 {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        border: 5px solid var(--soulsync-orange);
        border-radius: 100%;
        padding: 100px;
        width: 500px;
        z-index: 1;
        background-color: var(--soulsync-orange);
        color: white;
        position: relative;
    }

    .service_circle .img_section img {
        width: 100%;
    }

    .txt_section {
        font-size: 20px;
        width: 200%;
        /* background-color: red; */
        font-weight: bold;
    }

    .sub_container {
        margin: auto;
        padding: 70px 0;
        display: flex;
        flex-wrap: wrap;
        /* background-color: palegoldenrod; */
    }

    .left {
        width: 100%;
        font-weight: 700;
        text-align: left;
        font-size: 24px !important;
        color: var(--soulsync-orange);
    }

    .left img {
        padding: 50px 0;
        width: 90%;
    }

    .sub_container .right {
        width: 100%;
        /* height: 70vh; */
        padding: 0 0 50px 0;
        text-align: center;
        margin: auto;
    }

    .right .phone img {
        width: 50% !important;
    }

    .right .circle img {
        width: 100%;
    }

    .right .phone img,
    .right .circle img {
        margin: 30px 0;
    }

    .sub_container .right_mobile {
        position: relative;
        display: block;
        width: 100%;
    }

    .right_mobile img {
        width: 100%;
        display: block;
    }

    .left img {
        display: none;
    }

    .flex_button img {
        width: 100%;
    }

}