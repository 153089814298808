.marquee {
    font-size: 20px;
    padding: 10px 0;
    letter-spacing: 5px;
    color: var(--soulsync-orange);
    background-color: var(--soulsync-blue);
}

.loading {
    display: flex;
    margin: auto;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    animation: spin 1s linear infinite;
    border: 1px solid var(--soulsync-orange);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
        border-top: 2px solid var(--soulsync-orange);
    }

    25% {
        border-right: 4px solid var(--soulsync-orange);
        transform: rotate(90deg);
    }

    50% {
        border-right: 6px solid var(--soulsync-orange);
        transform: rotate(180deg);
    }

    75% {
        border-top: 8px solid var(--soulsync-orange);
        transform: rotate(270deg);
    }

    100% {
        border-bottom: 2px solid var(--soulsync-orange);
        transform: rotate(360deg);
    }
}

.loading1 {
    display: flex;
    margin: auto;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    animation: spin 1s linear infinite;
    border: 1px solid var(--soulsync-grey);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
        border-top: 2px solid var(--soulsync-grey);
    }

    25% {
        border-right: 4px solid var(--soulsync-grey);
        transform: rotate(90deg);
    }

    50% {
        border-right: 6px solid var(--soulsync-grey);
        transform: rotate(180deg);
    }

    75% {
        border-top: 8px solid var(--soulsync-grey);
        transform: rotate(270deg);
    }

    100% {
        border-bottom: 2px solid var(--soulsync-grey);
        ;
        transform: rotate(360deg);
    }
}

.embedButton {
    background-color: black;
    color: white;
    font-size: 24px;
    width: 150px;
    cursor: pointer;
}

.embedButton:hover {
    background-color: rgb(173, 2, 53);
}

.container_web {
    width: 100%;
    position: relative;
    display: flex;
    padding: 70px 0;
    align-items: center;
    justify-content: center;
}

.middle_web {
    width: 30%;
    margin-top: 100px;
    text-align: center;
    position: relative !important;
}

.middle_web .logo img {
    width: 70%;
}

.match_making {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto;
}

.match_making1 {
    font-size: 28px;
    padding-top: 10px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center !important;
    color: var(--soulsync-blue);
    /* background-color: red; */
}

.sign {
    width: 100%;
    margin-left: 75px;
    text-align: left !important;
}

.sign img {
    width: 35% !important;
}

.sub_heading {
    color: var(--soulsync-grey);
    font-size: 24px;
    padding: 20px 0;
    display: flex;
    letter-spacing: -1px;
    /* background-color: red; */
    text-align: center !important;
    margin: auto;
    /* width: 600px; */
}

.start_journey {
    color: white;
    font-size: 20px !important;
    padding: 20px 20px;
    border-radius: 10px;
    width: max-content;
    letter-spacing: 0;
    background-color: var(--soulsync-orange);
    border: 1px solid var(--soulsync-orange);
    margin: 50px auto 0 auto;
}

.start_journey:hover {
    cursor: pointer;
    background-color: white;
    color: var(--soulsync-orange);
    border: 1px solid var(--soulsync-orange);
}

.middle_web .sparkle {
    position: absolute;
    right: 0;
}

.middle_web .circle {
    position: absolute;
    right: 0;
    top: 70%;
}

.middle_web .circle_two {
    position: absolute;
    left: 0;
    top: 20%;
}

.middle_web .sparkle img,
.middle_web .circle img {
    width: 100%;
}

.right_web,
.left_web {
    width: 30%;
    position: relative !important;
    text-align: center !important;
}

.right_web .boy,
.left_web .girl {
    position: relative;
    z-index: 9;
}

.left_web .rope {
    position: absolute !important;
    /* background-color: rgb(255, 114, 114); */
    top: 50%;
    left: 0;
}

.right_web .rope {
    position: absolute !important;
    /* background-color: aqua; */
    top: 50%;
    right: 0;
}


.rope img {
    width: 100%;
    z-index: -9 !important;
}

.grp_img {
    display: none;
}


@media (max-width: 1440px) {
    .sign {
        width: 100%;
        margin-left: 50px;
        text-align: left !important;
    }

    .sign img {
        width: 35% !important;
    }
}


@media (max-width: 576px) {
    .container_web {
        flex-wrap: wrap;
        width: 100%;
        padding: 0px 10px;
    }

    .middle_web {
        width: 100%;
        text-align: left;
        position: relative !important;
    }

    .middle_web .logo img {
        width: 40%;
    }

    .match_making1 {
        font-size: 16px;
        text-align: left !important;
    }

    .sign {
        width: 45%;
        position: relative;
        margin-left: 0;
        margin-bottom: 20px;
    }

    .sign img {
        width: 100% !important;
    }

    .start_journey {
        margin-left: 0;
    }

    .sub_heading {
        /* width: 370px; */
        font-size: 20px;
        /* background-color: red; */
    }

    .grp_img {
        display: block;
        margin-top: 50px;
    }

    .grp_img img {
        width: 100%;
    }


    .middle_web .sparkle,
    .middle_web .circle,
    .right_web .boy,
    .left_web .girl,
    .left_web .rope,
    .right_web .rope,
    .rope img,
    .middle_web .circle_two {
        display: none;
    }


}