.container {
    width: 90%;
    margin: auto;
    /* padding: 50px 0; */
    /* background-color: red; */
}

.heading {
    /* background-color: pink; */
    padding: 50px 0;
    display: flex;
    align-items: center;
    color: var(--soulsync-blue);
}

.left {
    font-size: 40px;
    font-weight: 700;
}

.right {
    border: 1px solid var(--soulsync-orange);
    width: 500px;
    margin-left: 20px;
}

.sub_container {
    width: 90%;
    margin: auto;
    padding: 50px 0;
}

.sub_container_mobile {
    display: none;
}

.animation {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: var(--soulsync-blue);
    margin-bottom: 50px;
    /* font-weight: 700; */
    /* background-color: pink; */
}

.right_animation {
    width: 80%;
    line-height: 42px;
    font-size: 24px !important;
    letter-spacing: 0;
    /* background-color: red; */
}

.animation .left_animation img {
    width: 75%;
    margin-right: 20px;
}


@media (max-width: 576px) {
    .heading {
        display: flex;
        justify-content: center;
        align-content: center;
    }

    .left {
        font-size: 24px;
        font-weight: 700;
        width: 290px;
        width: max-content;
        white-space: nowrap;
    }

    .right {
        width: 100%;
    }

    .animation {
        display: flex;
        flex-wrap: nowrap;
    }

    .animation .left_animation img {
        /* width: 40%; */
        /* background-color: red; */
    }

    .right_animation {
        font-size: 16px !important;
        letter-spacing: 0;
        line-height: 20.83px;
        text-align: left;
        /* background-color: red; */
    }

    .sub_container {
        display: none;
    }

    .sub_container_mobile {
        display: block;
    }

    .sub_container_mobile .animation .left_animation {
        /* background-color: red; */
        margin: auto !important;
        text-align: center;
        width: 20%;
    }

    .sub_container_mobile .animation {
        display: flex;
    }

    .right_mobile {
        width: 100%;
        display: flex;
        justify-content: end;
    }

}