.container {
    width: 90%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: auto;
    justify-content: space-between;
    /* background-color: red; */
    padding-bottom: 100px;
}

.container .solution,
.container .problem {
    width: 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* border: 1px solid rgb(255, 0, 43); */
}

.problem {
    font-weight: 700;
    font-size: 40px;
    /* font-size: 2.5rem; */
    /* background-color: red; */
}

.container .solution {
    display: flex !important;
    text-align: right;
    justify-content: end;
    flex-direction: column;
    /* background-color: pink; */
    align-items: end;
    /* border: 1px solid red; */
}


.heading {
    /* background-color: pink; */
    /* padding: 50px 0; */
    height: 100px;
    display: flex;
    color: var(--soulsync-blue);
    align-items: center;
}

.solution .heading {
    display: flex;
    /* background-color: red; */
    justify-content: end;
}

.left {
    font-size: 40px;
    font-weight: 700;
}

.problem .heading .right,
.solution .heading .right {
    border: 1px solid var(--soulsync-orange);
    width: 300px !important;
    margin-left: 20px;
}

.solution .heading .right {
    margin-right: 20px;
    border: 1px solid var(--soulsync-orange);
    width: 300px !important;
}

.box {
    /* border: 1px solid pink; */
    width: 90%;
    display: flex;
    padding: 40px 0;
    align-items: center;
    justify-content: space-between;
}

.icon {
    width: 12%;
    margin: auto;
}

.icon img {
    width: 100%;
    display: block;
    text-align: center;
}

.problem .txt,
.solution .txt {
    width: 80%;
    display: flex;
    padding: 0 20px;
    flex-direction: column;
}

.problem .subheading1,
.solution .subheading1 {
    letter-spacing: 0;
    font-size: 24px;
    font-weight: 700;
    color: var(--soulsync-blue);
    height: 50px;
    /* background-color: red; */
}

.problem .subheading2,
.solution .subheading2 {
    padding-top: 50px;
    font-size: 18px;
    letter-spacing: 0;
    font-weight: 400;
    color: var(--soulsync-black);
    height: 100px;
}

.orange_line {
    width: 5px;
    height: 250px;
    border-radius: 10%;
    background-color: var(--soulsync-orange);
}

/* animations -- after Clients's CR  */
.container .problem .box .txt .subheading1,
.container .problem .box .txt .subheading2,
.container .solution .box .txt .subheading1,
.container .solution .box .txt .subheading2 {
    position: relative !important;
}

.container .problem .heading,
.container .solution .heading {
    position: relative !important;
}

.solution .star_img_heading {
    position: absolute !important;
    left: 10%;
    top: 0;
}

.problem .star_img_heading {
    position: absolute !important;
    right: 10%;
    top: 100%;
}

.star_new_img {
    position: absolute !important;
    left: 60%;
    bottom: -50px;
    z-index: -9;
    /* background-color: red; */
}

.star_new_img img {
    /* width: 100%; */
}

.heading_phone {
    display: none;
}

.problem .heading .left {
    /* background-color: red; */
    width: max-content !important;
    padding: 0;
    white-space: nowrap;
    text-align: left;
}

.problem .heading .right {
    text-align: left;
}


@media (max-width:1440px) {

    .container .solution .heading .left,
    .container .problem .heading .left {
        font-weight: 700;
        font-size: 40px !important;
        /* background-color: red !important; */
        width: 320px;
    }

    .container .solution .heading .left {
        width: 250px;
        /* background-color: aqua !important; */
    }

    .container .solution .heading .right,
    .container .problem .heading .right {
        width: 280px !important;
    }
}

@media (min-width:1366px) {

    .container .solution .heading .left,
    .container .problem .heading .left {
        font-weight: 700;
        font-size: 40px !important;
        /* background-color: red !important; */
        width: 400px;
        /* background-color: red; */
    }

    .container .solution .heading .left {
        width: 250px;
        /* background-color: aqua !important; */
    }

    .container .solution .heading .right,
    .container .problem .heading .right {
        width: 280px !important;
    }

    .subheading2 .star_new_img img #circle {
        margin-top: 100px;
        background-color: red !important;
    }
}



@media (max-width:576px) {


    .container {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 0;
    }

    .container .solution,
    .container .problem {
        width: 100%;
        display: flex;
    }

    .box {
        /* border: 1px solid pink; */
        width: 100%;
        padding: 20px 0;
    }


    .orange_line {
        width: 3px;
        height: 150px;
        border-radius: 10%;
        background-color: var(--soulsync-orange);
    }

    .icon {
        width: 15%;
    }

    .container .solution .heading .left,
    .container .problem .heading .left {
        font-size: 24px !important;
        width: max-content;
        white-space: nowrap;
        /* background-color: red; */
    }

    .container .solution .heading .left {
        font-size: 24px !important;
        width: max-content;
    }

    .container .solution .heading .right,
    .container .problem .heading .right {
        font-size: 24px !important;
        width: 100%;
    }

    .problem .subheading1,
    .solution .subheading1 {
        letter-spacing: 0;
        font-size: 16px;
        font-weight: 700;
    }

    .problem .subheading2,
    .solution .subheading2 {
        font-size: 12px;
        letter-spacing: 0;
        font-weight: 400;
        padding: 20px 0;
    }

    .problem .heading .left,
    .solution .heading .left {
        /* background-color: red; */
        font-size: 24px;
        letter-spacing: 0;
    }

    .solution .box {
        display: flex;
        flex-direction: row-reverse;
        /* background-color: red; */
        margin-top: 20px;
    }

    .solution .box .txt {
        width: 80%;
        text-align: left;
    }

    .star_new_img {
        position: absolute !important;
        left: 60%;
        bottom: -115px;
        z-index: -9;
        /* background-color: red; */
    }

    .box .txt .subheading2 .star_new_img img {
        display: none;
    }

    .container .solution {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        /* border: 2px solid pink; */
    }

    .container .solution .heading {
        display: none;
    }

    .heading_phone {
        display: block;
    }

    .solution .heading_phone {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 20px 0;
    }

    .heading_phone .left_phone {
        width: max-content;
        font-size: 24px;
        font-weight: bold;
        white-space: nowrap;
        padding-right: 20px;
        color: var(--soulsync-blue);
    }

    .heading_phone .right_phone {
        width: 100% !important;
        border: 1px solid var(--soulsync-orange);
    }

}