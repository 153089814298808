.container {
    width: 90%;
    margin: auto;
    padding: 50px 0;
}

.orange_container {
    border-radius: 30px;
    padding: 150px 0;
    text-align: center;
    background-color: var(--soulsync-orange);
    background-image: url('../../Images/Mask\ Group.png');
}

button {
    border: none;
    background-color: transparent;
}


.start_journey {
    font-size: 24px;
    /* text-transform: capitalize; */
    font-weight: 700;
    color: var(--soulsync-blue);
    background-color: white;
    width: max-content;
    margin: auto;
    font-weight: 700;
    padding: 30px 100px;
    border-radius: 60px !important;
    /* background-color: aqua; */
}

.start_journey:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.508);
    color: white;
}

/* .start {
    border-radius: 20px;
    background-color: white;
    width: max-content;
    margin: auto;
    font-size: 20px;
    font-weight: 700;
    padding: 50px 100px;
}

.start:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.583);
    color: white !important;
} */

.footer {
    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */
    padding: 100px 50px;
    justify-content: space-between;

}

.footer .left img {
    width: 100%;
}

.matchmaking {
    font-weight: 700;
    font-size: 24px;
    color: var(--soulsync-blue);
}

.match_making1 {
    font-size: 24px;
    padding-top: 10px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center !important;
    color: var(--soulsync-blue);
    /* background-color: red; */
    margin-left: -30px;
}

.sign {
    width: 100%;
    text-align: left !important;
}

.sign img {
    width: 50% !important;
}


.right {
    width: 50%;
    /* text-transform: capitalize; */
    font-size: 18px;
    display: flex;
    justify-content: end;
    align-items: end;
    flex-direction: column;
}

.follow {
    width: 100px;
    padding: 20px 0;
}

.icon {
    width: 100px;
    display: flex;
}

.icon a {
    width: 100%;
    margin-right: 20px;
    /* background-color: red; */
}

.icon img {
    display: block;
}

.right p {
    padding: 20px 0;
}

.foooter_last {
    width: 50%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
}


@media (max-width:1440px) {
    .right {
        width: 50%;
        /* text-transform: capitalize; */
        font-size: 18px;
        display: flex;
        justify-content: end;
        align-items: end;
        flex-direction: column;
    }

    .icon {
        width: 20%;
        display: flex;
    }

    .icon a {
        width: 100%;
        margin-right: 20px;
        /* background-color: red; */
    }

    .icon img {
        display: block;
    }

    .right p {
        padding: 20px 0;
    }
}

@media (max-width:576px) {

    .start_journey {
        letter-spacing: 0;
        text-transform: capitalize;
        /* background-color: red; */
        color: var(--soulsync-blue);
        font-size: 18px;
        padding: 10px 40px;
    }

    .start {
        font-size: 12px;
        letter-spacing: 0;
        font-weight: 700;
        padding: 10px 60px;
    }

    .footer {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding: 50px 0px;
    }

    .footer .left img {
        width: 50%;
    }

    .matchmaking {
        font-weight: 700;
        letter-spacing: 0;
        font-size: 12px;
        padding: 10px 0 40px 0;
        /* background-color: red; */
        /* width: 50%;    */
        color: var(--soulsync-blue);
    }

    .orange_container {
        border-radius: 15px;
        padding: 50px 0;
        text-align: center;
        background-color: var(--soulsync-orange);
        background-image: url('../../Images/Mask\ Group.png');
    }

    .right {
        /* width: 100%; */
        display: flex;
        justify-content: start;
        align-items: start;
        /* padding-bottom: 100px; */
    }

    .icon {
        width: 20%;
        display: flex;
    }

    .icon a {
        width: 90%;
        margin-right: 10px;
    }

    .icon a img {
        width: 24px;
        display: block;
    }

    .right .follow {
        padding: 10px 0;
        font-size: 14px;
        margin-top: 20px;
        letter-spacing: 0;
        /* background-color: red; */
    }

    .footer .left img {
        width: 70%;
    }

    .matchmaking {
        font-weight: 700;
        font-size: 24px;
        color: var(--soulsync-blue);
    }

    .match_making1 {
        font-size: 16px;
        padding-top: 0px;
        padding-left: 20px;
        text-align: left !important;
    }

    .sign {
        width: 100%;
        text-align: left !important;
    }

    .sign img {
        width: 50% !important;
    }

    .foooter_last {
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 0 0 100px 0;
        text-align: left;
    }

    .foooter_last a {
        margin-bottom: 10px;
    }
}