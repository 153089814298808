.container {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

}

.underlined {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-weight: bold;
    color: rgb(4, 4, 71);
    margin-right: 0.5rem;
    font-size: 2rem;
}

.underlined::after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 0;
    height: 7px;
    width: 100%;
    border: 4px solid #FFC27A;
    border-color: #FFC27A transparent transparent transparent;
    border-radius: 50%;
    cursor: pointer;
    transform-origin: left;

}

.underlined:hover {
    transform: perspective('11deg');
}