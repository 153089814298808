.container {
    width: 100%;
    margin: auto;
    padding: 50px 0;
    /* background-color: red; */
}

.heading {
    /* background-color: pink; */
    padding: 50px 0 0 0;
    display: flex;
    align-items: center;
}

.heading>.left {
    font-size: 40px;
    font-weight: 700;
    color: var(--soulsync-blue);
    width: max-content;
}

.heading>.right {
    border: 1px solid var(--soulsync-orange);
    width: 300px;
    margin-left: 20px;
}


.step_section {
    width: 100%;
    display: flex;
    align-items: center !important;
    justify-content: space-around;
    /* background-color: aqua; */
    padding: 0 0 150px 0;
}

.big_line {
    position: absolute;
    width: 100%;
    margin-bottom: 140px;
    z-index: -9 !important;
    /* display: none; */
}

.big_line img {
    width: 100%;
    /* display: none; */
    /* z-index: -9 !important; */
}

.first_section {
    width: 15%;
    z-index: -9;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* background-color: aqua; */
}

.section_img {
    width: 75%;
    z-index: 9 !important;
}

.section_img img {
    z-index: 99 !important;
    width: 100% !important;
    /* background-color: rgb(32, 24, 24) !important; */
}

.first_section img {
    width: 100%;
    z-index: 9;
    display: block;
    /* display: none; */
    /* background-color: rgb(255, 255, 255); */
}

.sub_heading1 {
    color: var(--soulsync-blue);
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 20px;
    letter-spacing: 0 !important;
    text-align: center;
    width: 350px;
    /* margin-top: -px; */
    /* height: 50px; */
    /* background-color: red; */
}

.sub_heading2 {
    color: var(--soulsync-grey);
    font-size: 18px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0 !important;
    /* text-align: justify; */
    height: 50px;
}


@media (max-width:1440px) {
    .big_line {
        margin-top: 10px !important;
    }

    .sub_heading1 {
        margin-top: 0px;
    }

    .first_section .section_img {
        width: 100%;
        z-index: 9;
        display: block;
        /* background-color: rgb(255, 0, 0); */
    }

    .heading>.left {
        font-size: 38px;
        font-weight: 700;
        color: var(--soulsync-blue);
        width: max-content;
    }
}

@media (max-width:1336px) {

    .heading>.left {
        font-size: 34px;
        font-weight: 700;
        color: var(--soulsync-blue);
        width: max-content;
    }

    .heading>.right {
        border: 1px solid var(--soulsync-orange);
        width: 200px;
        margin-left: 20px;
    }

}


@media (max-width: 576px) {

    .big_line {
        display: none;
    }

    .heading {
        padding: 50px 0 0 0;
        display: flex;
        /* flex-wrap: wrap; */
        /* align-items: center; */
    }

    .heading>.left {
        letter-spacing: 0;
        font-size: 24px;
        /* width: 100% !important; */
        font-weight: 700;
        color: var(--soulsync-blue);
        width: max-content;
    }


    .heading>.right {
        border: 1px solid var(--soulsync-orange);
        width: 250px;
        /* width: 100%; */
        /* display: none; */
        margin-left: 20px;
    }

    .heading img {
        display: none !important;
    }

    .step_section {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px 0 0px 0;
    }


    .line_year {
        display: none;
    }

    .first_section {
        width: 100%;
    }

    .sub_heading1 {
        font-size: 20px !important;
    }

    .sub_heading2 {
        font-size: 16px !important;
        padding: 0 40px;
    }

    .section_img {
        width: 65%;
    }

    .section_img img {
        padding-top: 40px;
        width: 70% !important;
        /* background-color: red; */
        margin: auto;
    }
}